import React, { useState } from 'react'

import MicRecorder from 'mic-recorder-to-mp3';
import { Dialog, DialogContent, LinearProgress } from '@mui/material';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function AppMp3Recorder({callback}) {

    const [dialogStatus,setDialogStatus] = useState(false);
    const [recording,setRecordingStatus] = useState(false);
    const [audioUrl,setAudioUrl] = useState(null);
    const [blobData,setBlobData] = useState(null);
    const [blocked,setBlocaedStatus] = useState(true);


    const onStart = () => {
        Mp3Recorder.start().then(()=>{
            setAudioUrl(null);
            setBlobData(null);
            setRecordingStatus(true);
        })
    }

    const onStop = () => {
        Mp3Recorder.stop().getMp3()
        .then(([buffer, blob]) => {
            setBlobData(blob);
            const _blobURL = URL.createObjectURL(blob)
            setAudioUrl(_blobURL);
            setRecordingStatus(false);
        }).catch((e) => console.log(e));
    }

    const upload = () =>{
        callback(blobData);
        setDialogStatus(false);
    }

    return (
        <>
            <button className="btn btn-outline-primary" onClick={()=>setDialogStatus(true)} >Record Audio</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContent>
                    <div className="text-center">
                        {!recording && <button type="button" className="btn btn-danger" onClick={onStart} >Start Recording</button>}
                        {recording && 
                            <div className="mt-3">
                                <h3 className="mb-3">Recording Audio...</h3>
                                <LinearProgress color="warning" /><button type="button" className="btn btn-danger mt-3" onClick={onStop} >Stop Recording</button>
                            </div>
                        }
                        {audioUrl !== null &&
                            <div className="mt-5 text-center">
                                <div>
                                    <audio controls>
                                        <source src={audioUrl} />
                                    </audio>
                                </div>
                                <div>
                                    <a download="text.mp3" href={audioUrl} className="btn btn-outline-primaru">Download</a>
                                    <button className="btn btn-primary" onClick={upload} >Upload</button>
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    
    )
}

export default AppMp3Recorder