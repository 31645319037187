import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import deviceTrackServices from '../deviceTrackServices';
import DeviceControl from './DeviceControl';
import {useSyncDevice} from '../../../../context/SyncContext'
import moment from 'moment';

function DeviceMQTT({AppAlert,deviceDetails,mqClient}) {

    const [topic,setTopic] = useState("PA_SYSTEM/"+deviceDetails.deviceName+"/");

    const [deviceState,setDeviceState]  = useState(null);

    const {syncingId, setSyncingId} = useSyncDevice();

    

    useEffect(()=>{
        mqClient.on('connect', () => {
            console.log('Connected for '+deviceDetails.deviceName);
            mqClient.subscribe(topic+"ack", function (err) {
                if (!err) {
                    console.log("subscribed to topic : ", topic+"ack")
                  // Publish a message to a topic
                    let message = {
                        "Device_ID":deviceDetails.deviceName,
                        "State":1
                    }
                    mqClient.publish(topic+'State',JSON.stringify(message))
                } else {
                    console.log("error on subscribe",err)
                }
            })



            mqClient.subscribe(topic+"TRACKResponse", function (err) {
                if (!err) {
                    console.log("subscribed to topic : ", topic+"TRACKResponse")
                } else {
                    console.log("error on subscribe",err)
                }
            })

        });
        return () =>{
            mqClient.unsubscribe(topic+"ack", function (error) {
                if (error) {
                  console.log(error)
                } else {
                  console.log('Unsubscribed ',topic+'ack')
                }
            })

            mqClient.unsubscribe(topic+"TRACKResponse", function (err) {
                if (!err) {
                    console.log("subscribed to topic : ", topic+"TRACKResponse")
                } else {
                    console.log('Unsubscribed ',topic+'Track')
                }
            })
        }
    },[mqClient])

    const publishMessage = (message) =>{
        console.log("publishing message");
        //mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        delete message.TEMP;
        console.log("device Message",message)
        mqClient.publish(topic+"command",JSON.stringify(message));
        console.log("message published");
    }

    const publishTestMessage = () =>{
        console.log("publishing message");
        mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        console.log("message published");
    }

    const publishTrack = (message) =>{
        console.log("publishing message on ",topic+"Track");
        //console.log(topic+"Track",'{"url":"'+url+'","trackNo":"'+trackNo+'"}');
        //mqClient.publish(topic+"Track",'{"url":"'+url+'","trackNo":"'+trackNo+'"}');
        //mqClient.publish(topic+"Track",'{"url":"urlwillbehere","trackNo":"'+trackNo+'"}');
        
        console.log(message)
        if(syncingId===null){
            setSyncingId(message.trackId)
            mqClient.publish(topic+"Track",JSON.stringify(message));
        } else{
            AppAlert("Snncing in progress plase wait","error")
        }
        

        console.log("message published");
    }

    const upateSyncStatus = (trackId,syncStatus) =>{
        let payload = {
            data:{
                id:trackId,
                syncStatus:syncStatus
            },
            type:"text"
        }
        deviceTrackServices.updateInline(payload).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            setSyncingId(null);
        })
    }

    mqClient.on('message', function (t, message) {
        // message is Buffer
        console.log("message received for "+t);
        let m = message.toString();
        let messageObj = JSON.parse(m);
        console.log(messageObj)

        if(t == topic+"TRACKResponse"){
            console.log(messageObj)
            if(messageObj.STATUS === "SUCCESS"){
                AppAlert("Track Sync Successfully","success")
                upateSyncStatus(messageObj.trackId,1)
            }
            else if(messageObj.STATUS === "DELETE"){
                AppAlert("Track Removed Successfully","error")
                upateSyncStatus(messageObj.trackId,0)
            } else {
                AppAlert("Device Memory Full","error")
                setSyncingId(null);
            }
                
        }

        
        if(t == topic+"ack" && messageObj.Device_ID === deviceDetails.deviceName){
            setDeviceState(messageObj);
        }
        //client.end()
    })

    console.log("i am rerendered for "+deviceDetails.deviceName);

    const [pendingDays,setPendingDays] = useState(0)
    useEffect(()=>{
        if(deviceDetails.validTill === null){
            setPendingDays(30)
        } else {
            let currentDate =  moment(new Date());
            let validTill = moment(deviceDetails.validTill);
            let _pendingDays = moment.duration(validTill.diff(currentDate)).asDays();
            setPendingDays( parseInt(_pendingDays))
        }
    },[])


    return (
        <div className="card mb-2">

                        <div className="card-header bg-primary text-white">
                            <span className="fw-bold">{deviceDetails.deviceTitle} ({deviceDetails.deviceName})</span>
                        </div>

                        {pendingDays < 0 && 
                            <div class="alert alert-danger m-2">
                                This device was valid till {moment(deviceDetails.validTill).format("LL")}, Please recharge asap.
                            </div>
                        }

                        {pendingDays >= 0 && 
                            <div className="card-body">
                                {pendingDays < 15 && 
                                    <div class="alert alert-danger">
                                        This device is valid till {moment(deviceDetails.validTill).format("LL")}, Please recharge asap for uninterrupted services.
                                    </div>
                                }
                                {deviceState === null && 
                                    <div className="text-center">
                                        <h6><CircularProgress/></h6>
                                        <h6>Getting Device State</h6>
                                    </div>
                                }
                                {deviceState !== null &&
                                    <DeviceControl 
                                        deviceState={deviceState}
                                        setDeviceState={setDeviceState}
                                        publishMessage={publishMessage}
                                        deviceId = {deviceDetails.id}
                                        latitude = {deviceDetails.latitude}
                                        longitude = {deviceDetails.longitude}
                                        publishTrack = {publishTrack}
                                    />
                                }
                            </div>
                        }
                    
                
                
        </div>

    )
}

export default DeviceMQTT