import React,{useState} from 'react'
import { Add, CheckCircle, Delete, Edit, Sync } from '@mui/icons-material';
import api from "../../../../api/api";
import withAlert from '../../../../hoc/withAlert';

import {useSyncDevice} from '../../../../context/SyncContext'
import { IconButton, LinearProgress, Tooltip } from '@mui/material';
import AppAudioRecorder from './AppAudioRecorder';
import AppMp3Recorder from './AppMp3Recorder';

function TrackFile({deviceId, AppAlert, trackDetails,trackNo ,publishTrack,onSuccess}) {

    const {syncingId} = useSyncDevice();

    const selectFile = (e) => {
        let data = new FormData();
        data.append('tmpFile', e.target.files[0]);
        data.append('trackId',trackDetails.id);
        data.append('trackNo',trackNo);
        data.append('deviceId',deviceId);
        api.post("upload/track",data).then( (res)=>{
            if(!res.data.error){
                AppAlert(res.data.msg);
                onSuccess();
            } else {
                AppAlert(res.data.msg,"error");
            }
        }).catch( (error) => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
        })
    }

    const uploadRecordedFile =(blobData) =>{
        
            let data = new FormData();
            data.append('tmpFile', blobData);
            data.append('trackId',trackDetails.id);
            data.append('trackNo',trackNo);
            data.append('deviceId',deviceId);
            api.post("upload/track",data).then( (res)=>{
                if(!res.data.error){
                    AppAlert(res.data.msg);
                    onSuccess();
                } else {
                    AppAlert(res.data.msg,"error");
                }
            }).catch( (error) => {
                AppAlert(error.msg,"error")
            }).finally(()=>{
            })
    }

    return (
        <>
            {syncingId !== trackDetails.id ?
                <div class="d-flex justify-content-between">
                    <div className="form-group">
                        <input 
                            type="file"
                            onChange = {
                                (e)=>{
                                    //getBase64(e);
                                    selectFile(e);
                                }
                            }
                            accept={".mp3"}
                            id={"track_"+trackDetails.id}
                            className="d-none"
                        />
                        {trackDetails.url == null ?
                            <label htmlFor={"track_"+trackDetails.id} className="btn btn-success" > <Add /> Audio Track </label>
                            :
                            <label htmlFor={"track_"+trackDetails.id} className={trackDetails.syncStatus == 0 ?  "btn btn-warning" :" btn btn-success"} > <Edit/> Audio Track </label>
                        }
                    </div>

                    {/* <AppAudioRecorder callback={uploadRecordedFile} /> */}
                    <AppMp3Recorder callback={uploadRecordedFile} />
                    {trackDetails.url !== null && 
                        <>
                            <audio controls>
                                <source src={trackDetails.url} />
                            </audio>
                            {trackDetails.syncStatus == 0 ? 
                                <button className="btn btn-warning" onClick={ () => 
                                    //publishTrack(trackDetails.url,trackNo,trackDetails.id,deviceId)
                                    publishTrack({url:trackDetails.url,trackNo:trackNo,trackId:trackDetails.id,deviceId:deviceId})
                                }>
                                    {syncingId === trackDetails.id ?
                                        "Syning In progress"
                                        :
                                        <Sync/>
                                    }

                                </button>
                                :
                                <div className="d-flex justify-content-start">
                                    <span className="text-success float-start"><CheckCircle style={{fontSize:50}}/></span>


                                    <Tooltip title="Delete Track From Device">
                                        <IconButton onClick={()=>
                                            publishTrack({url:"DELETE",trackNo:trackNo,trackId:trackDetails.id,deviceId:deviceId})
                                        }>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>


                                    
                                </div>
                            }
                        </>
                    }
                </div>
                :
                <div className="row">
                    <div className="col-md-12">
                    <LinearProgress color="warning" />
                    </div>
                    <div className="col-md-12 text-center">
                        <span className="fw-bold text-danger">Syncing In Progress... Please Wait!</span>
                    </div>
                    
                </div>
            }
        </>
    )
}

export default withAlert(TrackFile)




/*


const [base64,setBase64] = useState(null);


const getBase64 = (e) => {
        let file = e.target.files[0]
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          let _base64 = reader.result;
          console.log(_base64);
          setBase64(_base64);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
    };


{base64 &&
                            <>
                            <audio controls>
                                <source src={base64} />
                            </audio>
                            <button className="btn btn-warning" onClick={ () => publishTrack(base64,trackNo)}>
                            <Sync/>
                            </button>
                            </>
                        }

*/