import { Settings } from '@mui/icons-material';
import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import DeviceAdminForm from '../components/DeviceAdminForm';
import DeviceTrackAdmin from '../components/DeviceTrackAdmin';
import deviceAdminServices from '../deviceAdminServices';
import moment from 'moment';


function DeviceAdminList({AppAlert}) {

    const [deviceList,setDeviceList]      = useState(null);
    const [loading,setLoadingStatus]        = useState(false);

    const [userList,setUserList] = useState(null);
    const getUserList = () =>{
        deviceAdminServices.getUserList().then(res=>{
            setUserList(res.data);
        })
    }

    const getUserLabel=(userId)=>{
        let label = userList.find(m => m.value === userId)
        return label.firstName+" "+label.lastName;
    }

    const getDeviceList = () => {
        setLoadingStatus(true);
        deviceAdminServices.getList().then(res=>{
            setDeviceList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getUserList();
        getDeviceList()
    },[])

    const remove = (id) =>{
        deviceAdminServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getDeviceList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                    <span className="float-end"><DeviceAdminForm AppAlert={AppAlert} refreshList={getDeviceList} /></span>
                    </div>
                </div>
            
                <div className="row mt-2">
                    <div className="col-md-12">
                        {deviceList && userList && deviceList.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>DeviceId</th>
                                        <th>Device Display Name</th>
                                        <th>User Name</th>
                                        <th>Valid Till</th>
                                        <th>Location</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceList.map(device=>
                                        <tr key={device.id}>
                                            <td>{device.deviceName}</td>
                                            <td>{device.deviceTitle}</td>
                                            <td>{ getUserLabel(device.userId)}</td>
                                            <td>
                                                {device.validTill == null ?
                                                    "infinity"
                                                    :
                                                    moment(device.validTill).format("LL")}

                                            </td>
                                            <td>
                                                { (device.latitude == null || device.latitude === "") ?
                                                    <span>No Location defined</span>
                                                    :
                                                    <a className="btn btn-outline-primary" target='_blank' 
                                                    href={"https://www.google.com/maps/dir//"+device.latitude+", "+device.longitude}
                                                    >Show Location</a>
                                                }
                                            </td>
                                            <td></td>
                                            <td>
                                                <DeviceAdminForm AppAlert={AppAlert} refreshList={getDeviceList} data={device} userList={userList} />
                                                <ConfirmDialog id={device.id} confirm={remove} >Are you Sure You want to remove this device</ConfirmDialog>
                                                <DeviceTrackAdmin deviceId={device.id} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {!deviceList || deviceList.length == 0 &&
                            <h4 >No device declared click on "Add New" button to declare new device</h4>
                        }
                    </div>
                </div>
            </div>
        
        </AppLayout>
        
    )
}

export default withAlert(DeviceAdminList)