import { Add } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import React, { useState, useEffect } from 'react'
import ConfirmDialog from '../../../../components/ConfirmDialog';
import TextInline from '../../../../components/InlineEdit/TextInline';
import RestoreDialog from '../../../../components/RstoreDialog ';
import deviceTrackServices from '../deviceTrackServices';
import TrackFile from './TrackFile';

function ManageDeviceTrack({deviceId, deviceTrackList, publishTrack, getDeviceTrackList }) {
    const [dialogStatus,setDialogStatus] =  useState(false);

    const handleOpen = () =>{
        //getDeviceTrackList();
        setDialogStatus(true);
    }

    const handleClose = () =>{
        setDialogStatus(false);
    }

    const addNewTrack = () =>{
        console.log("Add new track for "+deviceId)
        deviceTrackServices.addNew({deviceId:deviceId}).then(res=>{
            console.log(res);
            getDeviceTrackList();
        }).catch(error=>{
            console.log(error)
        })
    }

    const remove = (id) =>{
        console.log("remove track ",id);
        deviceTrackServices.remove(id).then(res=>{
            getDeviceTrackList();
        }).catch(error=>{
            console.log(error);
        })
    }


    return (
    <>
        <button className="btn btn-outline-primary p-3" onClick={handleOpen} >Manage Track</button>
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <div className="row">
                    <div className="col-md-12">
                        {/* <button className="btn btn-primary float-end" onClick={addNewTrack}><Add/> Add a Track</button> */}
                    </div>
                </div>
                {deviceTrackList &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Track #</th>
                                        <th>Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceTrackList.map((track,i) =>
                                        <tr key={track.id} className={track.status == 0 && "table-danger"}>
                                            <td>{i+1}</td>
                                            <td>
                                                <TextInline
                                                    name="trackTitle" editId={track.id} value={track.trackTitle} 
                                                    updateInline={deviceTrackServices.updateInline} 
                                                />
                                            </td>
                                            <td><TrackFile deviceId={deviceId} trackDetails={track} trackNo={i+1} publishTrack={publishTrack} onSuccess={getDeviceTrackList} /></td>
                                            <td>
                                                {track.syncStatus === 0 && track.status === 1 && 
                                                    <ConfirmDialog id={track.id} confirm={remove} >Are you Sure You want to remove this track</ConfirmDialog>
                                                }
                                                {track.status === 0 &&
                                                    <RestoreDialog id={track.id} confirm={remove} >Are you Sure You want to retore this track</RestoreDialog>
                                                }
                                                
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
                <div className="row mt-2">
                    <div className="col-md-12">
                        <button className="btn btn-outline-primary float-end" onClick={handleClose} >Close</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default ManageDeviceTrack